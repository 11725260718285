import React, { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import { UpdateCard } from '../../components/Card/type';

const EditableTitle = (props: {
  onFinish: (values: UpdateCard) => void;
  title: string;
}) => {
  const { title, onFinish } = props;
  const inputRef = useRef<any>(null);

  useEffect(() => {
    inputRef.current?.focus({ cursor: 'end' });
  });

  return (
    <Form onFinish={onFinish} initialValues={{ title }}>
      <Form.Item name={'title'} label={''} noStyle>
        <Input
          ref={inputRef}
          onBlur={(e) => onFinish({ title: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </Form.Item>
    </Form>
  );
};

export default EditableTitle;
