import React, { useEffect, useRef, useState } from 'react';
import { Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { ProfileOutlined } from '@ant-design/icons';
import { Card, UpdateCard } from '../type';
import { useCardUpdateOnce } from '../helpers/useCardUpdateOnce';
import { useGetWorkplaceId, useRole } from '../../../utils/helpers/useRole';
import EditableTitle from '../../../utils/Copmonents/EditableTitle';

const Title = React.memo(({ data }: { data: Card | undefined }) => {
  const cardUpdate = useCardUpdateOnce(data?.id.toString());
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef<any>(null);
  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);

  useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus({ cursor: 'end' });
    }
  }, [isEdit]);

  const onFinish = ({ title }: UpdateCard) =>
    cardUpdate.mutateAsync({ title }).then(() => {
      setIsEdit(false);
    });

  return isEdit ? (
    <div style={{ marginTop: 8 }}>
      <EditableTitle onFinish={onFinish} title={data?.title ?? ''} />
    </div>
  ) : (
    <Typography.Title
      editable={
        role !== 'user' && {
          text: data?.title,
          onStart: () => {
            setIsEdit(true);
          },
        }
      }
      level={4}
    >
      {!isMobile && <ProfileOutlined style={{ marginRight: 8 }} />}
      <Typography.Text style={{ fontSize: '1em' }} type={'secondary'}>
        {data?.serialNumber}
      </Typography.Text>{' '}
      {data?.title}
    </Typography.Title>
  );
});
Title.displayName = 'Title';
export default Title;
