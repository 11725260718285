import React, { useContext, useEffect, useState } from 'react';
import { Column as ColumnType } from '../Column/types';
import {
  Button,
  Dropdown,
  Form,
  MenuProps,
  Modal,
  Popover,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import {
  CheckOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { CreateCard, UpdateCard } from '../Card/type';
import Card from '../Card/Card';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useGenericMutation } from '../../utils/hooks/useGenericMutate';
import { deleteColumn } from './api';
import { FilterContext } from '../BoardLayout/BoardLayout';
import { getDataColumn } from './helpers/getDataColumn';
import { useMassArchiveMutation } from './helpers/useMassArchiveMutation';
import { isMobile } from 'react-device-detect';
import CreateCardByTemplate from '../Card/components/CreateCardByTemplate';
import { Link } from 'react-router-dom';
import { useCreateSimpleCardMutation } from '../Card/helpers/useCreateSimpleCardMutation';
import { BOARD_URL } from '../Workspace/Board/api/BoardApi';
import { Board } from '../Workspace/Board/types';
import { useMyCard } from './helpers/useMyCard';
import { useGetWorkplaceId, useRole } from '../../utils/helpers/useRole';
import { useForm } from 'antd/es/form/Form';
import { ColumnWrapper } from './Column.style';
import { useColumnUpdateMutation } from './helpers/useColumnUpdateMutation';
import EditableTitle from '../../utils/Copmonents/EditableTitle';

const { useToken } = theme;

const { confirm } = Modal;

const Column = React.memo(({ column }: { column: ColumnType }) => {
  const { filterList } = useContext(FilterContext);
  const { token } = useToken();
  const [isEdit, setIsEdit] = useState(false);
  const [isCardCreate, setIsCardCreate] = useState(false);
  const simpleCreateCard = useCreateSimpleCardMutation();
  const [form] = useForm();
  const cardList = column.task;

  const { mutate: deleteMutate } = useGenericMutation<Board, number>(
    deleteColumn,
    [BOARD_URL, column.boardId?.toString()],
    (oldData, newData) => ({
      ...oldData,
      columnList: oldData.columnList.filter((col) => col.id !== newData),
    })
  );
  const getMyCard = useMyCard();

  useEffect(() => {
    document.addEventListener('keydown', getMyCard);

    return () => document.removeEventListener('keydown', getMyCard);
  }, [filterList]);

  const { mutateAsync: updateMutate } = useColumnUpdateMutation(
    column?.boardId.toString()
  );

  const onFinish = ({ title }: UpdateCard) =>
    updateMutate([column.id, { title }]).then(() => setIsEdit(false));

  const onFinishCard = (values: CreateCard) => {
    simpleCreateCard
      .mutateAsync({
        ...values,
        columnId: column.id,
        numberInColumn:
          cardList?.length !== undefined ? cardList.length : undefined,
      })
      .then(() => setIsCardCreate(false));
  };

  const onCreateCard = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  useEffect(() => {
    const listener = () => {
      setIsCardCreate(false);
    };
    window.document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, []);

  const { mutateAsync: massArchiveMutate } = useMassArchiveMutation();
  const workplaceId = useGetWorkplaceId();
  const role = useRole(workplaceId ?? 0);

  const showPromiseConfirm = () => {
    confirm({
      title: (
        <>
          Вы действительно хотите отправить все карточки из колонки{' '}
          <Typography.Text underline>{column.title}</Typography.Text> в архив?
        </>
      ),
      icon: <ExclamationCircleFilled />,
      onOk() {
        return massArchiveMutate(column.id);
      },
    });
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    borderRadius: token.borderRadius,
    transition: '1s',
    padding: 1,
    background: isDraggingOver ? token.colorFill : token.colorBgLayout,
  });

  const columnMenu: MenuProps['items'] = [
    {
      label: 'Удалить',
      key: 'delete',
      onClick: () => deleteMutate(column.id),
    },
    {
      label: 'Редактировать',
      key: 'edit',
      onClick: () => setIsEdit(true),
    },
    {
      label: 'Отправить карточки в архив',
      key: 'achive',
      onClick: showPromiseConfirm,
    },
  ];

  const data =
    cardList && Object.keys(filterList).length
      ? getDataColumn(cardList, filterList)
      : cardList;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.currentTarget.value += '\n';
      } else {
        form.submit();
      }
      event.preventDefault();
    }
  };

  return (
    <ColumnWrapper $backgroundColor={token.colorBgLayout}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 8,
        }}
      >
        {
          <Typography.Title level={5}>
            {isEdit ? (
              <EditableTitle title={column.title} onFinish={onFinish} />
            ) : (
              <>
                {column.title}{' '}
                <Typography.Text type={'secondary'}>
                  {data?.length ?? ''}
                </Typography.Text>
              </>
            )}
            {column.status === 'COMPLETE' && (
              <Tooltip
                title={'Задачи в этом столбце помечаются как выполненные'}
              >
                <CheckOutlined
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                    color: token.colorSuccess,
                  }}
                />
              </Tooltip>
            )}
          </Typography.Title>
        }
        {role === 'lead' && (
          <Dropdown
            trigger={['click']}
            placement={'bottomRight'}
            menu={{ items: columnMenu }}
          >
            <Button type={'text'} size={'small'}>
              ...
            </Button>
          </Dropdown>
        )}
      </div>
      <Droppable
        droppableId={'column' + column.id}
        direction={'vertical'}
        type={'CARD'}
      >
        {(providedCard, snapshot) => (
          <div
            {...providedCard.droppableProps}
            ref={providedCard.innerRef}
            id={'column' + column.id}
            style={getListStyle(snapshot.isDraggingOver)}
            className={'column-inner'}
          >
            <div id={'column-inner' + column.id}>
              <div style={{ overflowX: 'hidden' }}>
                {data?.map((card, index) => (
                  <Draggable
                    draggableId={card?.id?.toString() + 'card'}
                    index={index}
                    key={card?.id}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          overflow: snapshot.isDragging ? undefined : 'hidden',
                        }}
                        ref={provided.innerRef}
                        className="card-root"
                      >
                        <Link
                          to={`card/${card?.id}`}
                          onClick={(e) => {
                            if (!card?.id) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                          style={{ color: 'inherit', display: 'block' }}
                        >
                          <Card card={card} isDragging={snapshot.isDragging} />
                        </Link>
                      </div>
                    )}
                  </Draggable>
                ))}
                {providedCard.placeholder}
              </div>
            </div>
          </div>
        )}
      </Droppable>
      {role !== 'user' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Popover
            open={isCardCreate}
            arrow={false}
            trigger={'click'}
            placement={isMobile ? 'bottom' : 'right'}
            destroyTooltipOnHide={true}
            onOpenChange={setIsCardCreate}
            content={
              <Form
                form={form}
                style={{ marginTop: 8 }}
                onFinish={onFinishCard}
                onClick={(e) => e.stopPropagation()}
              >
                <Form.Item
                  name={'title'}
                  rules={[{ required: true, message: '' }]}
                >
                  <TextArea
                    rows={2}
                    onKeyDown={handleKeyDown}
                    placeholder={'Введите заголовок для карточки'}
                  />
                </Form.Item>
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  loading={simpleCreateCard.isLoading}
                >
                  Добавить карточку
                </Button>
              </Form>
            }
          >
            <Button type={'text'} onClick={onCreateCard}>
              <PlusOutlined /> Добавить карточку
            </Button>
          </Popover>
          <CreateCardByTemplate column={column} />
        </div>
      )}
    </ColumnWrapper>
  );
});
Column.displayName = 'Column';
export default Column;
